/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from 'react';
import './Hero.css';
import image1 from '../../assests/heroimage.jpg'; // Import multiple images
import image2 from '../../assests/heroimage2.jpg';
import image3 from '../../assests/heroimage3.jpg';

const images = [image1, image2, image3]; // Store all your images in an array

const Hero = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Function to update the current image index
  const updateImageIndex = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  // Use useEffect to set up the image change interval
  useEffect(() => {
    const interval = setInterval(updateImageIndex, 2800); // Change image every 5 seconds (adjust as needed)

    return () => {
      clearInterval(interval); // Clean up the interval on component unmount
    };
  }, []);

  return (
    <div className="hero-container" id="home">
      <div className="image-container">
        <img
          src={images[currentImageIndex]}
          alt="Hero Image"
          className="image1"
        />
        <div className="overlay"></div>
        <div className="content">
          <h1 className="hero-heading">Welcome to GreenAg</h1>
          <p className="hero-subheading">
            Explore sustainable agriculture solution.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Hero;