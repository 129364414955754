import React from 'react';
import './Domain.css';

const Domain = () => {
    return (
        <div className="domain-container" id="domain">
            <div className="domain-content">
                {/* <div className="column card1">
                    <h2>What is GreenAg</h2>
                    <p>
                        This section provides an overview of the specific area or field of agriculture that GreenAg focuses on.
                    </p>
                </div> */}
                <div className="column card2">
                    <h2>Background</h2>
                    <p style={{textAlign: "justify"}}>
                    This research aims to create a knowledge assistance model and a platform that can help preserve and manage traditional eco-friendly remedies for vegetable crop diseases in Sri Lanka. The platform will use advanced techniques to identify and verify vegetable diseases, predict severity of diseases and collect, preserve and suggest traditional remedies.
                    </p>
                </div>
                <div className="column card2">
                    <h2>Research Problems</h2>
                    <p style={{textAlign: "center"}}>
                    1. Loss of traditional remedies
                    </p>
                    <p style={{textAlign: "center"}}>
                    2. Lack of government support
                    </p>
                    <p style={{textAlign: "center"}}>
                    3. Lack of knowledge transfer
                    </p>
                    <p style={{textAlign: "center"}}>
                    4. Impact of chemical approaches
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Domain;