import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <div className='footer-container'>
      <hr/>
      <div className='footer-content'>
        <h3>&copy; 2023 GreenAg</h3>
        <p>2023-343</p>
      </div>
    </div>
  );
}

export default Footer;