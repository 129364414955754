import React from 'react';
import pdfIcon from '../Document/pdfdocument.jfif';
import './Document.css';

const Document = () => {

  const downloadFile = (filePath, fileName) => {
    const link = document.createElement('a');
    link.href = process.env.PUBLIC_URL + '/' + filePath; 
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const filesToDownload = [
    {
      fileName: 'TAF-TMP-23-343.pdf',
      filePath: 'Document/TAF/TAF-TMP-23-343.pdf',
      category: 'Project TAF'
    },
    {
      fileName: 'RP Charter_TMP-23-343.pdf',
      filePath: 'Document/Project Charter/RP Charter_TMP-23-343.pdf',
      category: 'Project Charter'
    },
    {
      fileName: 'Proposal-Report-23-IT20150020.pdf',
      filePath: 'Document/Project Proposal/Proposal-Report-23-IT20150020.pdf',
      category: 'Project Proposal'
    },
    {
      fileName: 'Proposal-Report-23-IT20152864.pdf',
      filePath: 'Document/Project Proposal/Proposal-Report-23-IT20152864.pdf',
      category: 'Project Proposal'
    },
    {
      fileName: 'Proposal-Report-23-IT20153304.pdf',
      filePath: 'Document/Project Proposal/Proposal-Report-23-IT20153304.pdf',
      category: 'Project Proposal'
    },
    {
      fileName: 'Proposal-Report-23-IT20076566.pdf',
      filePath: 'Document/Project Proposal/Proposal-Report-23-IT20076566.pdf',
      category: 'Project Proposal'
    },
    {
      fileName: 'Proposal-Presentation-2023-343.pdf',
      filePath: 'Document/Project Proposal/Proposal-Presentation-2023-343.pdf',
      category: 'Project Proposal'
    },
    {
      fileName: 'PP1_2023-343.pdf',
      filePath: 'Document/PP1/PP1_2023-343.pdf',
      category: 'PP1'
    },
    {
      fileName: 'IT20150020 - Project status document-1.pdf',
      filePath: 'Document/Status Document 1/IT20150020 - Project status document-1.pdf',
      category: 'Status Document 01'
    },
    {
      fileName: 'IT20152864 - Project status document-1.pdf',
      filePath: 'Document/Status Document 1/IT20152864 - Project status document-1.pdf',
      category: 'Status Document 01'
    },
    {
      fileName: 'IT20153304 - Project status document-1.pdf',
      filePath: 'Document/Status Document 1/IT20153304 - Project status document-1.pdf',
      category: 'Status Document 01'
    },
    {
      fileName: 'IT20076566 - Project status document-1.pdf',
      filePath: 'Document/Status Document 1/IT20076566 - Project status document-1.pdf',
      category: 'Status Document 01'
    },
    {
      fileName: 'PP2_2023-343.pdf',
      filePath: 'Document/PP2/PP2_2023-343.pdf',
      category: 'PP2'
    },
    {
      fileName: 'Poster.pdf',
      filePath: 'Document/PP2/Poster.pdf',
      category: 'PP2'
    },
    {
      fileName: 'IT20150020 - Project status document-2.pdf',
      filePath: 'Document/Status Document 2/IT20150020 - Project status document-2.pdf',
      category: 'Status Document 02'
    },
    {
      fileName: 'IT20152864 - Project status document-2.pdf',
      filePath: 'Document/Status Document 2/IT20152864 - Project status document-2.pdf',
      category: 'Status Document 02'
    },
    {
      fileName: 'IT20153304 - Project status document-2.pdf',
      filePath: 'Document/Status Document 2/IT20153304 - Project status document-2.pdf',
      category: 'Status Document 02'
    },
    {
      fileName: 'IT20076566 - Project status document-2.pdf',
      filePath: 'Document/Status Document 2/IT20076566 - Project status document-2.pdf',
      category: 'Status Document 02'
    },
    {
      fileName: 'Research Paper.pdf',
      filePath: 'Document/Research Paper/Research Paper.pdf',
      category: 'Final Report'
    },
    {
      fileName: 'Final Report - IT20150020.pdf',
      filePath: 'Document/Final Report/IT20150020.pdf',
      category: 'Final Report'
    },
    {
      fileName: 'Final Report - IT20152864.pdf',
      filePath: 'Document/Final Report/IT20152864.pdf',
      category: 'Final Report'
    },
    {
      fileName: 'Final Report - IT20153304.pdf',
      filePath: 'Document/Final Report/IT20153304.pdf',
      category: 'Final Report'
    },
    {
      fileName: 'Final Report - IT20076566.pdf',
      filePath: 'Document/Final Report/IT20076566.pdf',
      category: 'Final Report'
    },
    {
      fileName: 'Final Group Report_2023-343.pdf',
      filePath: 'Document/Final Report/Final Group Report_2023-343.pdf',
      category: 'Final Report'
    },
    {
      fileName: '2023-343_Final_Presentation_PPT.pdf',
      filePath: 'Document/Final Report/2023-343_Final_Presentation_PPT.pdf',
      category: 'Final Report'
    },
    {
      fileName: 'Logbook - IT20150020.pdf',
      filePath: 'Document/Logbook/IT20150020.pdf',
      category: 'Logbook'
    },
    {
      fileName: 'Logbook - IT20152864.pdf',
      filePath: 'Document/Logbook/IT20152864.pdf',
      category: 'Logbook'
    },
    {
      fileName: 'Logbook - IT20153304.pdf',
      filePath: 'Document/Logbook/IT20153304.pdf',
      category: 'Logbook'
    },
    {
      fileName: 'Logbook - IT20076566.pdf',
      filePath: 'Document/Logbook/IT20076566.pdf',
      category: 'Logbook'
    }
  ];  

  const groupedFiles = filesToDownload.reduce((acc, file) => {
    if (!acc[file.category]) {
      acc[file.category] = [];
    }
    acc[file.category].push(file);
    return acc;
  }, {});

  return (
    <div id='documents' className="center-content">
      <h1 className='header1'>Documents</h1>

      {Object.keys(groupedFiles).map((category, index) => (
        <div key={index}>
          <h3 className="header">{category}</h3>
          <div key={index} className="file-row">
          {groupedFiles[category].map((file, index) => (
            <div key={index} className="file-container">
              <span className="download-link" onClick={() => downloadFile(file.filePath, file.fileName)}>
                <img src={pdfIcon} alt={file.fileName} className="pdf-icon" />
                <br/>
                <h5 className="file-title">{file.fileName}</h5>
              </span>
            </div>
          ))}
          </div>
        </div>
      ))}
    </div>
  );
}

export default Document;