import React from 'react';
import './Demo.css'; // Import your CSS file for styling
import video1 from '../Demo/video.mp4';

const Demos = () => {

  return (
    <div className='Demos-container' id='demo'>
      <h1 className='Demos-heading'>
        Demo
      </h1>
      <div className='Demos-list'>
        <div className='Demo-video'>
        <video src={video1} width="600" height="300" controls="controls" />
        </div>
      </div>
    </div>
  );
}

export default Demos;