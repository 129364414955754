import React from 'react';
import './Technologies.css';
import Card from '@mui/material/Card';
import image1 from '../Technologies/images/python.png';
import image2 from '../Technologies/images/flutter.webp';
import image3 from '../Technologies/images/googlecolab.png';
import image4 from '../Technologies/images/firebase.webp';
import image5 from '../Technologies/images/pythonanywhere.jpg';
import image6 from '../Technologies/images/androidstudio.jfif';
import image7 from '../Technologies/images/vscode.png';

const Technologies = () => {
  return (
    <div className='technologys-container' id='technologies'>
      <h1 className='technologys-heading'>
        Tools & Technologies
      </h1>
      <div className='card-container'>
      <Card title="Python">
  <img src={image1} style={{width:"100px"}} alt='Python' />
</Card>

        <Card title="Flutter">
          <img src={image2} style={{width:"200px"}} alt='Flutter' />
        </Card>
        <Card title="Google Colab">
          <img src={image3} style={{width:"100px"}} alt='Google Colab' />
        </Card>
        <Card title="Firebase">
          <img src={image4} style={{width:"200px"}} alt='Firebase' />
        </Card>
        <Card title="PythonAnyWhere">
          <img src={image5} className='pythonanywhereimage' style={{width:"470px"}} alt='PythonAnywhere' />
        </Card>
        <Card title="Android Studio">
          <img src={image6} style={{width:"100px"}} alt='Android Studio' />
        </Card>
        <Card title="VS Code">
          <img src={image7} style={{width:"170px"}} alt='VS Code' />
        </Card>
      </div>
    </div>
  );
}

export default Technologies;