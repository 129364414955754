import React from 'react';
import './Achivment.css';
import achievementImage1 from './achivement.png';

const Achievements = () => {
  const achievementsData = [
    {
      title: "IJCA Publication",
      description: "https://www.ijcaonline.org/archives/volume185/number37/32932-2023923178",
      image: achievementImage1
    }
  ];

  return (
    <div className='achievements-container' id='achivments'>
      <h1 className='achievements-heading'>
        Achievements
      </h1>
      <div className='achievements-list'>
        {achievementsData.map((achievement, index) => (
          <div key={index} className='achievement'>
            <h2>{achievement.title}</h2>
            <p>
              <a href={achievement.description} target="_blank" rel="noopener noreferrer">
                {achievement.description}
              </a>
            </p>
            <img src={achievement.image} alt={`Achievement ${index + 1}`} />
          </div>
        ))}
      </div>
      <br/>
    </div>
  );
}

export default Achievements;