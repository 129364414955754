import React, {useState} from 'react'
import './Header.css'
import Bars from '../../assests/bars.png'
import {Link} from "react-scroll"
import Logo from '../../assests/rplogo.png';

const Header = () => {
  const mobile = window.innerWidth<=768 ? true: false;
  const [menuOpened, setMenuOpened] = useState(false)
  return (
    <div className='header-container'>
        {menuOpened === false && mobile === true ? (
      <div style={{backgroundColor: "var(--appColor)", padding: "0.5rem", borderRadius: "5px"}} onClick={()=> setMenuOpened(true)}>
      <img src={Bars} alt="" style={{width: "1.5rem", height: "1.5rem", padding:"1rem"}}/>
      </div>
      ) : (
      <ul className='header-list'>
      <div className="logo-container">
          <li onClick={() => setMenuOpened(false)}><Link to='home' span={true} smooth={true}><img src={Logo} alt="GrrenAg Logo" className="logo" /></Link></li>
          </div>
            <li onClick={() => setMenuOpened(false)}><Link to='domain' span={true} smooth={true}>Domain</Link></li>
            <li onClick={() => setMenuOpened(false)}><Link to='milestones' span={true} smooth={true}>Milestones</Link></li>
            <li onClick={() => setMenuOpened(false)}><Link to='documents' span={true} smooth={true}>Documents</Link></li>
            <li onClick={() => setMenuOpened(false)}><Link to='technologies' span={true} smooth={true}>Tools & Technologies</Link></li>
            <li onClick={() => setMenuOpened(false)}><Link to='demo' span={true} smooth={true}>Demo</Link></li>
            <li onClick={() => setMenuOpened(false)}><Link to='achivments' span={true} smooth={true}>Achivments</Link></li>
            <li onClick={() => setMenuOpened(false)}><Link to='aboutus' span={true} smooth={true}>About Us</Link></li>
            <li onClick={() => setMenuOpened(false)}><Link to='contactus' span={true} smooth={true}>Conatct Us</Link></li>
      </ul>
      )}
    </div>
  )
}

export default Header